.zc-button, .zc-button-sm {
  appearance: none;
  color: $black;
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 700;
  font-size: 1.2em;
  letter-spacing: -0.03em;
  border: 0;
  padding: 10px 20px;
  text-transform: uppercase;
  cursor: pointer;
  text-decoration: none;
  display: inline-block;
  position: relative;
  transition: background-size 30ms ease-out, transform 200ms ease-out;
  background: $yellow url('../images/bg-blood.png') no-repeat 97% 50%;
  background-size: 0 auto;
  transform: rotate(-0deg);

  &:hover {
    background-size: 70px auto;
    text-decoration: none;
    transform: rotate(-7deg);
  }
}

.zc-button-sm {
  font-size: .8em;
  letter-spacing: -0.01em;
  padding: 5px 10px;
}
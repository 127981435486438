@import url('https://fonts.googleapis.com/css2?family=Caveat&family=Roboto+Condensed:wght@400;700&display=swap');
@import "assets/styles/variables.scss";
@import "assets/styles/reset.scss";
@import "assets/styles/buttons.scss";

#root {
  display: grid;
  grid-template-rows: auto 1fr auto;
}

body {
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 400;
  color: $black;
  background: transparent url('./assets/images/bg.jpg') repeat center center;

  strong {
    font-weight: 700;
  }
}

h2, h3 {
  font-weight: 700;
}

h2 {
  font-size: 2em;
}

h3 {
  font-size: 1.6em;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.zc-section {
  display: grid;
  padding: 0 20px 20px 20px;
  width: 100%;
  max-width: 680px;
  max-width: 960px;
  justify-self: center;
  margin-top: 40px;
}

.zc-tooltip {
  &__trigger {
    display: grid;
    align-content: center;
    justify-content: center;
    width: 23px;
    height: 23px;
    border-radius: 23px;
    background: $yellow;
    font-weight: 700;
    font-size: .9rem;
  }

  &__wrapper {
    max-width: 300px;
    display: grid;
    gap: 10px;
  }
}
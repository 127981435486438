@import '../assets/styles/variables.scss';

.zc-survivor-identity-sheet {
  display: grid;
  justify-items: center;

  &__form {
    display: grid;
    gap: 60px;
    padding-bottom: 60px;
    width: 100%;
    justify-items: center;
  }

  &__group {
    display: grid;
    gap: 20px;
    width: 100%;
    justify-self: center;

    h3 {
      display: grid;
      grid-auto-flow: column;
      justify-content: flex-start;
      align-items: center;
      gap: 10px;
    }

    @media screen and (min-width: 1024px) {
      &--2-col {
        grid-template-columns: repeat(2, 1fr);
        column-gap: 40px;

        h3, > .zc-survivor-identity-sheet__field-error {
          grid-column: 1/3;
        }
      }


      &--3-col {
        grid-template-columns: repeat(3, 1fr);
        column-gap: 40px;

        h3, > .zc-survivor-identity-sheet__field-error {
          grid-column: 1/4;
        }
      }
    }
  }

  &__separator {
    display: block;
    height: 5px;
    width: 100%;
    border: 0;
    background: transparent url('../assets/images/bg-header.png') no-repeat top center;
    background-size: cover;
  }

  &__field {
    display: grid;
    gap: 5px;
    align-content: flex-start;

    > label {
      font-weight: 700;
    }
  }

  &__field-wrapper {
    display: grid;
    grid-template-columns: 1fr auto;
    gap: 5px;
    align-items: center;
  }

  &__field-error {
    color: red;
    font-size: 0.9em;
  }

  &__actions {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
  }

  &__action {
    display: grid;
    grid-template-columns: 15px 1fr;
    align-items: center;
    gap: 5px;
    cursor: pointer;

    input {
      margin: 0;
    }

    &--disabled {
      pointer-events: none;
      opacity: .3;
    }
  }

  input[type="text"], select {
    font-family: 'Roboto Condensed', sans-serif;
    font-size: 1em;
    font-weight: 400;
    padding: 5px;
    border: 3px solid rgba($black, .3);
    transition: all 200ms ease-out;
    outline: 0;

    &:focus {
      border-color: $black;
    }
  }

  #pdf-output {
    width: 90vw;
  }

  &__img-base {
    display: none;
  }

  @media screen and (min-width: 1024px) {
    #pdf-output {
      width: 60vh;
      height: 94vh;
      position: fixed;
      right: 3vh;
      top: 3vh;
    }

  }
}
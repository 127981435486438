.zc-archetypes {
  display: grid;
  gap: 20px;

  &.zc-section {
    max-width: 960px;
  }

  &__list {
    display: grid;
    gap: 80px;
    align-items: flex-start;
  }

  &__item {
    display: grid;
    gap: 10px;
    justify-items: flex-start;
    align-items: flex-start;

    img {
      max-width: 100%;
    }
  }

  > .zc-button {
    justify-self: center;
  }

  @media screen and (min-width: 1024px) {
    &__list {
      grid-template-columns: repeat(3, 1fr);
      grid-column: 20px;
      grid-row: 80px;
    }
  }
}
.zc-header {
  display: grid;
  justify-content: center;
  padding: 20px;
  background: transparent url('../assets/images/bg-header.png') no-repeat top center;
  background-size: contain;

  img {
    border: 0;
    max-width: 100%;
  }
}
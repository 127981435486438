.zc-footer {
  width: 100%;
  display: grid;

  &__wrapper {
    display: grid;
    grid-auto-flow: column;
    justify-content: space-between;
    justify-self: center;
    max-width: 100%;
    align-items: center;
  }

  &:after {
    content: '';
    width: 100%;
    height: 10px;
    background: transparent url('../assets/images/bg-header.png') no-repeat top center;
    background-size: cover;
  }
}
@import '../assets/styles/variables.scss';

.zc-home {
  gap: 20px;
  text-align: center;
  align-content: flex-start;

  > .zc-button {
    justify-self: center;
  }

  &__header {
    max-width: 640px;
    justify-self: center;
  }

  &__selector {
    margin-top: 20px;
    display: grid;
    gap: 20px;
    justify-items: center;
  }

  &__selector-option {
    display: grid;
    justify-items: center;
    justify-self: stretch;
    align-content: flex-end;
    gap: 10px;
    padding: 70px 20px 20px;
    border: 5px solid $black;
    background: transparent url('../assets/images/bg-header.png') no-repeat top center;
    background-size: contain;
  }

  @media screen and (min-width: 1024px) {
    &__selector {
      gap: 40px;
      grid-template-columns: repeat(3, 1fr);
    }

    &__selector-title {
      grid-column: 1/4;
    }
  }
}